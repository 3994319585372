
.contact input[type=text], .contact input[type=email], .contact select, .contact textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  .contact textarea {
    min-height:150px;
    }
  
  .contact input[type=submit] {
    cursor: pointer;
    margin: 0 auto;
    display: flex;
  }  
  .contact .submit-btn{
      background-color: #da50eb;
    color: #fff;
    padding: 2px 35px 6px;
    font-size: 20px;
    margin-top: 30px !important;
    border-radius: 5px;
    font-weight: 600;
    display: inline-block;
    border: 1px solid #da50eb;
  }
  .contact .submit-btn:hover{
       color: #da50eb;
    text-decoration: none;
    border: 1px solid #da50eb;
    background: transparent;
}
.contact .bg-whit {
    background-color: #fff;
    border-radius: 20px;
    color: #86387B;
    padding: 50px 30px 50px;
    position: relative;
    z-index: 9;
}
.contact form{
    position: relative;
    z-index: 999;
}
.contact .planet_green {
    max-width: 300px;
    opacity: 0.9;
    position: absolute;
    left: -10%;
    top: 60%;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.contact{
    position: relative;
}
#product-detail.contact .ufo {
    position: absolute;
    right: 0;
    top: 40%;
    filter: drop-shadow(0px 0px 0px #ffffff8c);
}