.connect h2 {
    font-size: 40px;
    font-weight: 600;
}
.about .cld-med {
    position: absolute;
    right: 10%;
}
.about .hero-section .content{
    padding-top:80px;
}
.about-us{
    position: relative;
    background-repeat: repeat;
    background-image: url(../utils/assets/bg-upload.png);
    background-size: contain;
    position: relative;
    background-position: 100% 100%;
    padding: 80px 0;
}
.about .planet-yellow{
position: absolute;
top: 50%;
right: 0;
z-index: -1;
}
.about-us .bg-twinkle {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twinkle.png);
    background-size: cover;
    position: relative;
    background-position: center center;
}
.about .planet-blue {
    top: 35%;
    position: absolute;
    left: -22%;    
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.connect .planet_green{
    position: absolute;
    left: -5%;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.about-us .planet-green {
    position: absolute;
    left: 10%;
    max-width: 150px;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.about-us .ufo {
    position: absolute;
    right: 20% !important;
    filter: drop-shadow(0px 0px 0px #ffffff8c);
}
.about-us .cld-med {
    position: absolute;
    bottom: 0%;
    max-width: 150px;
}
.about-us h2 {
    padding-bottom: 20px;
    font-size: 28px;
}
.about p{
    padding: 8px 0;
}
.about-us .astronaut {
    position: absolute;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
    max-width: 350px;
    right: 10%;
}
.about .shooting{
    position: absolute;
    top: -40%;
    max-width: 150px;
}
.about footer .planet-pnk {
    max-width: 400px;
    position: absolute;
    right: 0;
    bottom: 60%;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.about .connect.bg-twinkle {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twinkle.png);
    background-size: cover;
    position: relative;
    background-position: center center;
    padding-top: 80px;
}
@media (max-width:1200px){
    .about-us{
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media (max-width:768px){
	.about-us {
    padding: 30px 0;
	}
	.about .hero-section .content {
    padding-top: 30px;
	}
	.about .connect.bg-twinkle {
    padding-top: 30px;
    }
    #product-detail .hero-section h1 {
        font-size: 34px;
        padding: 0 5px 10px;
    }
    .about-us h2 {
        font-size: 22px;
    }
    .connect h2 {
        font-size: 34px;
    }
    .contact .hero-section .spacing {
        padding: 30px 0;
    }
    #product-detail .hero-section h2{
        font-size: 22px;}
}