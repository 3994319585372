nav{
    position: fixed;
    width: 100%;
}
.nav-link{
	color: white;
}

.navbar-dark .navbar-toggler-icon{

	border-radius: 1rem 20rem;
	border: 2 rem dotted;
	background: white;
}

.navbar-nav.navbar-center {
    position: absolute;
    left: 50%;
	transform: translatex(-50%);
	color: white;
}
.nav-link{
	color: white;
}

.jtron-custom{
	background: transparent;
}
.jtron2{
	margin-top: 12rem;
}
.navigation li {
    margin: 0 20px;
}
.log {
    margin: 0 10px;
}
.log-in {
    margin: 0 20px;
}
.navigation li:first-child {
    margin-left: 0;
}
.navigation li:last-child {
    margin-right: 0;
}
.dropdown-item {
    cursor: pointer;
    font-size: 0.8rem;
}
a.dropdown-item {
    font-size: 0.9rem;
}
a.let-connect {
    background-color: #da50eb;
    color: #fff;
    padding: 6px 10px 8px;
    font-size: 16px;
    font-weight: normal;
    border-radius: 5px;
    display: inline-block;
    border: 1px solid #da50eb;
}
a.let-connect:hover {
	color: #fff;
    text-decoration: none;
    border: 1px solid #da50eb;
	background: transparent;
}
.navbar-light .navbar-toggler {
    color: rgb(255 255 255 / 98%);
    background: #ffffff52;
    padding: 5px;
    font-size: 15px;
    border-radius: 1px;    
    margin: 5px 0 5px;
}
.nav.navbar.navbar-expand-lg.navbar-light{
    z-index: 999;
}
@media (min-width:992px){
.header-sticky {
    position: relative;
    height: 75px;
}
nav.navbar.navbar-expand-lg.navbar-light {
    position: fixed;
    width: 100%;
    background: linear-gradient(to right, #06073c 0%, #03005ffc 100%);
    top: 0;
    z-index: 999;
}
}
@media (max-width:992px){
    
    a.nav-link.let-connect {
        min-width: 200px;
        margin-bottom: 10px;
    }
	.navigation li{
		margin:0;
    }
    .dropdown-menu{
        text-align: center !important;
    }
}
@media (max-width:768px){
a.let-connect {
    display: block;
}
}
