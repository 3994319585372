*{margin:0; padding:0;}
body{
    color: rgb(255, 255, 255);
    background: linear-gradient(to right, rgb(6, 7, 60) 0%, rgb(3, 0, 95) 100%);
    font-family: Oxygen;
}
:focus {
    outline: -webkit-focus-ring-color auto 0px;
}
.login {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-stars.svg);
    background-size: cover;
    background-position: 100% 100%;
}
::placeholder {
  color: #da50eb;
}
.login-form .row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.spacing{
    padding: 80px 0;
	
}
.inner-log {
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 0px 15px 2px #0000004a;
}
.right-section {
    text-align: center;
    padding: 0 30px;
	color:#da50eb;
}
.right-section input {
    width: 100%;
    border-radius: 30px;
    margin: 10px 0;
    text-align: left;
    padding: 8px 10px;
    border: none;
    background: #f2f2f2;
    font-size: 14px;
}
.remember input{
	width:auto;
	margin-right:5px;
}
.remember label{
    display: flex;
    align-items: center;
	margin:0;
}
.remember a{	
    color: #da50eb;
}
.remember {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
}
.right-section h2{
    color: #060934;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
}
.right-section h6{
    color: #060934;
    font-size: 14px;
    font-weight: 600;
	margin-top:15px;
}
.right-section button {
    width: 100%;
    background: #da50eb;
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    margin-top: 10px;
    color: #fff;
}
@media (min-width:992px){    
.h-60{
    height:50px;
}
}
@media (max-width:768px){
.inner-log img{
	border-radius:20px;
}
.inner-log {
		border-radius:20px;
	}
.right-section  {
    padding: 35px 30px 20px;
}
}