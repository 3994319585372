*{margin:0; padding:0;}

body{

    
  
    color: rgb(255, 255, 255);
    background: linear-gradient(to right, rgb(6, 7, 60) 0%, rgb(3, 0, 95) 100%);
    font-family: Oxygen;
    
    .gif{
        position: fixed;
        left: 50%;
        left: 50%;
        width: 50%;
        transform: translatex(-50%);
        z-index: 100;
        border: 1px solid;
        border-radius: 200px;
        top:25%;
    }

    gif_default{
    position: fixed;
    z-index: 100;
    left: 50%;
    transform: translatex(-50%);
    }

    
    .d-none{
        display:none;
    }
    .carousel-control-next, .carousel-control-prev {
        width: 4%;
    }
    
:focus {
    outline: -webkit-focus-ring-color auto 0px;
}
.txt-center{
	text-align:center;
}
.dis-f{
	display:flex;
	justify-content:center;
	align-items:center;
}
.spacing{
    padding: 80px 0;	
}
#object-demo .hero-section{
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-stars.svg);
    background-size: cover;
    background-position: 100% 100%;	
	position:relative;
}
#object-demo .bg-twinkle{
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twinkle.png);
    background-size: cover;
	position:relative;
}
#object-demo .upload-section {
    background-repeat: repeat;
    background-image: url(../utils/assets/bg-upload.png);
    background-size: contain;
    position: relative;
    background-position: 100% 100%;
    padding: 70px 0 40px;
}
#object-demo .planet-green{
	position:absolute;
	right:0;
}
#object-demo .hero-section h1::before,
#object-demo .hero-section  h1::after {
    display: inline-block;
    content: "";
    border-top: .3rem solid #da50eb;
    width: 3rem;
    margin: 0 1rem;
    transform: translateY(-0.5rem);
}
#object-demo .hero-section h1 {
    font-size: 40px;
    font-weight: 600;
    padding-bottom: 10px;
}
#object-demo .hero-section h2{
    font-size: 25px;
    font-weight: 600;
    padding-bottom: 25px;
}
#object-demo .hero-section p{
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
}
#object-demo .planet-yellow {
    top: 35%;
    position: absolute;
    right: 0;
	max-width:200px;
}
#object-demo .cld-small {
    position: absolute;
    left: -15px;
    top: 20px;
	max-width:150px;
}
#object-demo .dis-flex{
	display:flex;
	justify-content:space-between;
}
#object-demo .upload-section input[type=file]{
	padding:7px;
	width:220px;
	background:#ffd710;
	border-radius: 5px;
    text-align:left;
    overflow: hidden;
    z-index: 9999;
    text-align: center;
}
body #object-demo .img-gallery {
    background: #fff;
    border: 10px solid #ccc;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    margin-top: 30px;
}
.img-gallery.car {
    margin-top: 26px !important;
}
#object-demo .upload-section a.upload {
    padding: 7px;
    width: 220px;
    background: #ffd710;
    border-radius: 5px;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    text-align: center;
    z-index: 9999;
    text-align: center;
    position: relative;
}
#object-demo .upload-section img {
    max-width: 100%;
    max-height: 375px;
}
#object-demo .download-section{
	text-align:right;
}
#object-demo .images-padding{
	padding:0 25px;
}
#object-demo .download-section a {
    padding: 7px;
    width: 220px;
    background: #55cbf2;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    text-align: center;
}
#object-demo .img-gallery {
    background: #fff;
    border: 10px solid #ccc;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;	
	margin-top:30px;
}
#object-demo .button-ex{
    text-align: center;
    margin-top: 30px;
}
#object-demo .run-btn {
    background-color: #da50eb;
    color: #fff;
    padding: 0px 30px 4px;
    font-size: 25px;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 5px;
    display: inline-block;    
    border: 1px solid #da50eb;
}
#object-demo .run-btn:hover {
    color: #fff;
    text-decoration: none;
    border: 1px solid #da50eb;
    background: transparent;
}
#object-demo .keyword-step{
	position:relative;
}
#object-demo .keyword-step .step img{
	max-width:150px;
	padding-bottom:20px;
}
#object-demo .keyword-step h2{
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
}
#object-demo .step-detail {
    max-width: 200px;
    padding: 20px;
    margin: 0 auto;
}
#object-demo .step-detail h4{
    font-weight:600;
}
.step-execute {
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.line{
	width:200px;
}
#object-demo .planet-g {
    position: absolute;
    left: -28%;
    bottom: 48%;
    max-width: 600px;	
    filter: drop-shadow(0px 0px 0px #ffffff8c);
}
#object-demo .planet-pink{
	position:absolute;
	right:0;
	max-width:200px;
}
#object-demo .rocket-btm {
    position: absolute;
    left: 20px;
    max-width: 150px;
    bottom: -7%;
}
#object-demo .cloud-m {
    position: absolute;
    left: 16%;
    max-width: 150px;
    bottom: -26%;
}
#object-demo .planet-pnk{
    position: absolute;
    right: 0;
    max-width: 300px;
}
#object-demo .socialmedia img{
	margin:10px 20px;
}
#object-demo footer{
	position:relative;
}
.mrgn-btn {
    margin-left: 25px;
}
.run-btn.object{
    min-width: 300px;
}
#object-demo footer p{
	font-size:12px;
	margin-top:20px;
}
#object-demo .cloud-btm{
    position: absolute;
    left: -10%;
    max-width: 300px;
    bottom:0px;
}
#object-demo textarea::placeholder {
    color: #ccc;
  }
  .ocr .card {
    flex-direction: row;
    background-color: transparent;
    background-clip: border-box;
    border: 0px solid rgba(0,0,0,.125);
}
.ocr .card-clr p{
    margin:0 auto;
    margin-bottom: 1rem;
}
  #object-demo textarea {
      overflow: auto;
      resize: vertical;
      width: 100%;
      min-height: 380px;
      border: none;
      background: transparent;
      padding: 20px;
  }
  
#object-demo .upload-doc a {
    padding: 7px;
    width: 220px;
    background: #fbd510;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    text-align: center;
}
.ocr .hero-section h1::before, .ocr .hero-section h1::after {
    display: inline-block;
    content: "";
    border-top: .3rem solid #da50eb;
    width: 2rem;
    margin: 0 1rem;
    transform: translateY(-1rem);
}
}
.popup-log h4 {
    color: #0a0a36;
    font-size: 33px;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 600;
}
.popup-log .run-btn{
    margin-top:20px !important;
}
.popup-log .modal-body{
padding: 0 20px 30px;
}
.popup-log .modal-header, .popup-log .modal-footer{
    border:none;
}
.pop-modal{
display: flex;
justify-content: center;
align-items: center;
min-height: 100%;
}
.btn-primary.pop-load.focus, .btn-primary.pop-load:focus {
    color: #fff;
    background-color:#ffd710;
    border-color:#ffd710;
    box-shadow: 0 0 0 0rem rgba(38,143,255,.5);
}
.btn-primary.pop-load:not(:disabled):not(.disabled).active, 
.btn-primary.pop-load:not(:disabled):not(.disabled):active,
.show>.btn-primary.pop-load.dropdown-toggle
{
    color: #fff;
    background-color:#ffd710;
    border-color:#ffd710;
    box-shadow: 0 0 0 0rem rgba(38,143,255,.5);
}
.btn-primary.pop-load:hover {
    color: #fff;
    background-color:#ffd710;
    border-color:#ffd710;
    box-shadow: 0 0 0 0rem rgba(38,143,255,.5);
}
button.btn.btn-primary.pop-load{
    padding: 7px;
    width: 220px;
    background: #ffd710;
    border-radius: 5px;
    text-align: left;
    overflow: hidden;
    z-index: 9999;
    text-align: center;
}
.pop-modal .run-btn{
    background-color: #da50eb;
    color: #fff;
    padding: 0px 30px 4px !important;
    font-size: 20px  !important;
    }
    .pop-modal .run-btn:hover{
        color: #da50eb !important;
        padding: 0px 30px 4px !important;
        font-size: 20px  !important;
        }
        @media (max-width:1200px){
            
#object-demo .upload-section {
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
        }
@media (max-width:991px){
    body #object-demo .images-padding {
        text-align: center;
        padding: 25px;
    }
}
@media (max-width:768px){
	.illustration-mob{
		display:none;
	}
    .book.mrgn-btn {
        margin-left: 0;
    }
        body .step-execute {
            display: inline-block;
        }
        body .line {
            display: none;
        }
        body #object-demo .img-gallery {
            max-height: 100%;        
            min-height: 350px;
        }
        body #object-demo textarea {
            min-height: 350px;
        }
        body #object-demo .upload-section {
            padding: 30px 0 30px;
        }
        body.spacing{padding:30px 0;}
        .hero-section .spacing{
            padding:30px 0 0;
        }
        body #object-demo .hero-section h2 {
            padding-bottom: 0px;
        }
        #object-demo .button-ex {
            margin-top: 0px !important;
        }
        body #object-demo .step-detail {
            max-width: 100%;
            padding: 0px 0 20px;
        }
}
@media (max-width:576px){
    body #object-demo .hero-section h1::before, body #object-demo .hero-section h1::after {
        display: none;}
        body #object-demo .hero-section h1 {
            font-size: 35px;
        }       
                
.popup-log h4 {
    color: #0a0a36;
    font-size: 27px;
    letter-spacing: 0;
    line-height: 40px;
}
}
@media (max-width:415px){
    body #object-demo textarea {
        min-height: 300px;
    }
    body #object-demo .img-gallery {
        max-height: 100%;
        min-height: 300px;
    }
}