*{margin:0; padding:0;}
body{
    color: rgb(255, 255, 255)!important;
    background: linear-gradient(to right, rgb(6, 7, 60) 0%, rgb(3, 0, 95) 100%);
    font-family: Oxygen;
}
:focus {
    outline: -webkit-focus-ring-color auto 0px;
}
::placeholder {
  color: #da50eb;
}

.spacing{
    padding: 80px 40px;
	
}
.txt-center{
	text-align:center;
}
.mrgn-btn{
    margin-left:15px;
}
.dis-f{
	display:flex;
	justify-content:center;
	align-items:center;
}
.spacing{
    padding: 80px 0;	
}
.spacing-l{
    padding: 20px 0;	
}
.spacing-p{
    padding: 0px 0 40px;	
}
.run-btn {
    background-color: #da50eb;
    color: #fff;
    padding: 10px 35px 10px;
    font-size: 16px;
    margin-top: 30px;
    border-radius: 5px;
    display: inline-block;    
    border: 1px solid #da50eb;
}
.industries a:hover {
    background: #fff;
    color: #0056b3 !important;
}
.demo-btn{
    background-color: #da50eb;
    color: #fff;
    padding: 0px 30px 4px;
    font-size: 25px;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 5px;
    display: inline-block;
}
a{
    cursor: pointer;
}
a.run-btn:hover {
    color: #fff;
    text-decoration: none;
    border: 1px solid #da50eb;
    background: transparent;
    cursor: pointer;
}
#product-overview .hero-section h1::before,
#product-overview .hero-section  h1::after {
    display: inline-block;
    content: "";
    border-top: .3rem solid #da50eb;
    width: 2rem;
    margin: 0 1rem;
    transform: translateY(-0.5rem);
}
#product-overview .hero-section h1 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 10px;
}
#product-overview .hero-section{
	position:relative;
}
#product-overview .hero-section h2{
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 25px;
}
#product-overview .product h2{
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 10px;
}
#product-overview .product h4{
    font-size: 25px;
    padding-bottom: 25px;
}
#product-overview .product p {
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.5px;
}
#product-overview .bg-sky {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-upload.png);
    background-size: cover;
    position: relative;
    background-position: 100% -10%;
}
#product-overview .bg-purple {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-purple.png);
    background-size: contain;
    position: relative;
    background-position: 100% 100%;
    background-repeat: repeat;
}
#product-overview.over .bg-purple {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-purple.png);
    background-size: cover;
    position: relative;
    background-position: 100% 100%;
    background-repeat: repeat;
}
#product-overview .bg-twinkle {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twink.png);
    background-size: contain;
    position: relative;
    background-position: center center;
}
#product-overview footer.bg-twinkle{	
    background-size: cover;
}
#product-overview footer{
	position:relative;
}
#product-overview footer p{
	font-size:12px;
	margin-top:20px;
}
#product-overview .socialmedia img{
	margin:10px 20px;
}
#product-overview .planet-pk {
    position: absolute;
    width: 350px;
    right: 0;
    top: 46%;
}
#product-overview .planet-green{
	position:absolute;
	max-width:300px;
	left:-10%;
    filter: drop-shadow(0px 0px 0px #ffffff8c);
}
#product-overview .product{
	position:relative;
}
#product-overview .planet-pink{
	position:absolute;
	right:0;
	top:-10%;
    filter: drop-shadow(0px 0px 0px #ffffff8c);
}
#product-overview .cloud-b {
    position: absolute;
    bottom: -15%;
    max-width: 150px;
    left: 20%;
}
#product-overview .cloud-med {
    position: absolute;
    max-width: 200px !important;
    right: 20%;
    bottom: 45%;
}
#product-overview .keyword-para {
    position: relative;
}
#product-overview .object-detect {
    position: relative;
}

#product-overview .planet-sky{
	position:absolute;
	left:-25%;
	top:-20%;
    max-width: 60% !important;
    filter: drop-shadow(0px 0px 0px #ffffff8c);
    opacity:0.7;
}
#product-overview .cloud-btm {
    position: absolute;
    left: -10%;
    max-width: 300px;
    bottom: 0px;
}
#product-overview .planet-pnk{
    position: absolute;
    right: 0;
    max-width: 300px;
    filter: drop-shadow(0px 0px 0px #ffffff8c);
}
#product-overview .cloud-m {
    position: absolute;
    left: 16%;
    max-width: 150px;
}#product-overview .ufo {
    position: absolute;
    max-width: 200px;
    left: 0;
}
#product-overview .p-g {
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 0.7;
}
#product-overview  .planet-yellow {
    position: absolute;
    right: 0;
    top: 0%;
    opacity: 0.8;
}
.crowd-density.bg-twinkle{
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twinkle.png);
    background-size: cover;
    position: relative;
    background-position: center center;
}
.rckt-s{
    position: absolute;
    right: 0;
    top: 75%;
    width: 175px;
}
.footer-spacing{
    padding:50px 0;
}
@media (min-width:992px){
    
.h-90{
    height:100px;
}
    .mrgin-left{
        padding-left:30px !important;
    }
    .mrgin-right{
        padding-right:30px !important;
    }
}
@media (max-width:992px){
    #product-overview .bg-purple {
        background-repeat: no-repeat;
        background-size: cover;
    }    
    #product-overview  .product img{
        margin-bottom:25px;
        padding:0 20px 30px;
    }
    #product-detail .bg-white {
        background-color: #fff;
        border-radius: 20px;
        color: #86387B;
        min-height: 400px;
        padding: 30px;
        text-align: center;
    }
}
@media (max-width:768px){
    .demo-btn {
        margin-bottom: 20px;
    }
	.illustration-mob{
		display:none;
	}
	body .spacing {
    padding: 30px 0;
	}
    #product-overview .product {
        text-align: center;
    }
    #product-overview .planet-sky {
        position: absolute;
        left:60%;
        z-index: -1;
        max-width: 40% !important;
    }
    #product-overview .cloud-btm {
        opacity: 0.3;
    }
    #product-overview .product h2 {
        font-size: 30px;}
    #product-overview .product h4 {
        font-size: 24px;}
    #product-overview .planet-green {
        opacity: 0.8;
    }
	#product-overview .hero-section h2{
		padding-bottom:0;
	}
	#product-overview .product img {
    padding: 0 20px 0;
	}
	.space-margin img {
    margin-bottom: 40px;
	}
	#product-overview .product h2 {
    padding-bottom: 0;
	}
	#product-overview .product h4 {
    padding-bottom: 0;
	}
	#product-overview .product.bg-sky .spacing-l{
		padding-bottom:0;		
	}
	#product-overview footer .spacing {
    padding: 0;
    }
	.back-twinkle {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twinkle.png);
    background-size: cover;
    background-position: 100% 45%;
}
.space-margin {
    margin: 30px 0;
}
#product-overview .bg-twinkle {
    background-repeat: repeat;
    background-image: url(../utils/assets/bg-twink.png);
    background-size: contain;
    position: relative;
    background-position: center center;
}
    
.crowd-density.bg-twinkle{
    background-image: none !important;
}
#product-overview .hero-section h1 {
    font-size: 34px;
}
#product-overview .hero-section h2 {
    font-size: 22px;
}
.footer-spacing {
    padding: 20px 0;
}
}