*{margin:0; padding:0;}
body{
    color: rgb(255, 255, 255);
    background: linear-gradient(to right, rgb(6, 7, 60) 0%, rgb(3, 0, 95) 100%);    
    font-family: Oxygen;
}
:focus {
    outline: -webkit-focus-ring-color auto 0px;
}
.txt-center{
	text-align:center;
}
.dis-f{
	display:flex;
	justify-content:center;
	align-items:center;
}
#product-detail .hero-section{
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-stars.svg);
    background-size: cover;
    background-position: 100% 100%;	
	position:relative;
}
#product-detail .planet-green {
    position: absolute;
    right: 0;
    top: 15%;
}
#product-detail .planet-pink{
	position:absolute;
	left:-15%;
}
#product-detail .hero-section h1::before,
#product-detail .hero-section  h1::after {
    display: inline-block;
    content: "";
    border-top: .3rem solid #da50eb;
    width: 3rem;
    margin: 0 1.3rem;
    transform: translateY(-0.5rem);
}
#product-detail .hero-section.optical h1::before, #product-detail .hero-section.optical h1::after {
    display: inline-block;
    content: "";
    border-top: 0.3rem solid #da50eb;
    width: 2rem;
    margin: 0 1rem;
    transform: translateY(-0.5rem);
}
 .case-title h2   {
     font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 50px;
}
#product-detail .hero-section h1 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 10px;
}
#product-detail .hero-section h2{
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 25px;
}
#product-detail .hero-section p{
    font-size: 17px;
    line-height: 30px;
}
#product-detail .hero-section ul {
    font-size: 18px;
    text-align: left;
    padding: 0 20px 0 40px;
}
#product-detail .hero-section li {
    padding:10px 0 10px 10px;
}
.run-btn {
    background-color: #da50eb;
    color: #fff;
    padding: 0px 30px 4px;
    font-size: 25px;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 5px;
    display: inline-block;
    border: 1px solid #da50eb;
}

.demo-btn{
    background-color: #da50eb;
    color: #fff;
    padding: 0px 30px 4px;
    font-size: 25px;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 5px;
    display: inline-block;
}
a.run-btn:hover {
    color: #fff;
    text-decoration: none;
    border: 1px solid #da50eb;
    background: transparent;
}
#product-detail img{
	filter: drop-shadow(0px 0px 10px #ffffff8c);
}
#product-detail .planet-pink {
    top: 35%;
    position: absolute;
    left: -10%;
}
#product-detail .cld-small {
    position: absolute;
    left: 0;
}
#product-detail .cld-small {
    position: absolute;
    left: 20px;
    top: 20px;
}
#product-detail .cld-med {
    position: absolute;
}
#product-detail .ufo {
    position: absolute;
    right: 20px;
}
#product-detail .ufo {
    position: absolute;
    right: 20px;
    top: 80%;
	filter: drop-shadow(0px 0px 0px #ffffff8c);
}
#product-detail .cases-section h2{
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 50px;
}
#product-detail .cases-section p{
    font-size: 17px;
    line-height: 30px;
}
#product-detail .bg-star{	
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-stars.svg);
    background-size: cover;
    background-position: 100% 100%;	
} 
#product-detail .cases-section {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-case.png);
    background-size: cover;
    position:relative;
    background-position: 100% -50%;
    background-repeat: repeat;
    background-size: contain;
}
#product-detail .bg-white img{
	margin:0 auto;
	display:flex;
	padding-bottom:30px;
}
#product-detail .bg-white {
    background-color: #fff;
    border-radius: 20px;
    color: #86387B;
    min-height: 560px;
    padding: 50px 30px 30px;
}
#product-detail .bg-white h4{
    font-weight: 600;
    line-height: 40px;
}
#product-detail .cards {
    padding-top: 80px;
}
#product-detail .planet-blue {
    position: absolute;
    right: 0;
    filter: drop-shadow(0px 0px 0px #ffffff8c);
    top: 18%;
    z-index: -1;
}
#product-detail .astr {
    position: absolute;
    left: 0;
    filter: drop-shadow(0px 0px 0px #ffffff8c);
    top: 18%;
    z-index: -1;
}
.quote{
    position:relative;
}
.quote.bg-twinkle {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twinkle.png);
    background-size: cover;
    position: relative;
    background-position: center center;
}
.quote h3 {
    font-size: 28px;
    font-weight: 600;
    line-height: 45px;	
    text-shadow: 1px 1px 2px #131313;
}
#product-detail .process-section img {
    filter: drop-shadow(0px 0px 0px #ffffff8c); 
}
#product-detail .process-section h4{
    font-size: 24px;
    font-weight: 600;	
    margin-bottom:20px;
}
#product-detail .product p {
    font-size: 17px;
    line-height: 30px;
}
.industries h3 {
    font-size: 28px;
    font-weight: 600;
    line-height: 45px;
    padding: 0 20px;
    margin: 0 auto;
    width: 75%;
    margin-bottom: 15px;
}
.industries a{
    display: block;
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 8px 0 12px;
    font-size: 18px;
    color: #fff;
    margin:20px 0;
}
.industries a:hover{
    text-decoration:none;
}
.price-content {
    border-radius: 20px;
    background-image: url(../utils/assets/price-bg.png);
    color: #131313;
    padding: 40px;
    width: 100%;
    min-height: 350px;
    margin: 0 35px;
    background-size: cover;
    position:relative;	
    box-shadow: 0px 0px 4px 0px #100f0f;
}
.price-content span{
    font-size: 30px;
    font-weight: 600;
    color:#fff;
    padding:25px 0 0;
}
.price-content h5{
    font-size: 30px;
    padding: 35px 0;
    font-weight: 600;
}
.price-content p{
    color: #555;
    line-height: 35px;
    font-size:18px;
}
.pricing a.run-btn {
    position: absolute;
    margin: 0;
    padding: 5px 30px;
    font-size: 20px;
    margin: 20px;
}
.price-content.middle-price h5{
    font-size:30px;
    padding: 40px 0;
}
.price-content.middle-price {
    border-radius: 20px;
    background-image: url(../utils/assets/price-bg.png);
    padding: 20px;
    min-height: 400px;
    margin: 0 20px;
    box-shadow: 0px 0px 4px 0px #100f0f;
}
.price-content.middle-price span {
    font-size: 50px;
    padding: 0;
}
.pricing .middle-price a.run-btn {
    padding: 5px 30px;
    font-size: 25px;
}
.pricing a.run-btn:hover {
    color: #da50eb;
    text-decoration: none;
    border: 1px solid #da50eb;
    background: #fff;
}
.pricing h2 {
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-bottom: 40px;
    margin-bottom: 30px;
}
.pricing h2::before,
.pricing h2::after {
    display: inline-block;
    content: "";
    border-top: .3rem solid #da50eb;
    width: 2rem;
    margin: 0 1rem;
    transform: translateY(-0.5rem);
}
footer{
    position:relative;
}
footer.bg-twinkle{
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twinkle.png);
    background-size: cover;
    position: relative;
    padding:50px 0;
}
footer p{
    font-size:12px;
    margin-top:20px;
    margin-bottom:0;
}
.object-detail .bg-sky{
    background-repeat: repeat;
    background-image: url(../utils/assets/bg-case.png);
    background-size: contain;
    position: relative;
    background-position: 100% 100%;
}
.socialmedia img{
    margin:10px 20px;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.asteroid-round {
    max-width: 200px;
    position: absolute;
    left: 0;
    top: 0;
}
.quote .cloud-btm{
    max-width: 150px;
    position: absolute;
    left: 5%;
    bottom: 0;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.quote .cloud-med{
    max-width: 200px;
    position: absolute;
    right: 0;
    top: 0;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.process-section .bg-purple {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-purple.png);
    background-size: contain;
    position: relative;
    background-position: 100% 100%;
    background-repeat: repeat;
}
.fashion .process-section .bg-purple {
    background-size: cover;
}
.process-section.bg-twinkle {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twink.png);
    background-size: cover;
    position: relative;
    background-position: center center;
}
.process-section .customer{
    position:relative;
}
.process-section .bus-intel{
    position:relative;
}
.process-section .planet_blue {
    max-width: 400px;
    position: absolute;
    left: -20%;
    top: 0;
    filter: drop-shadow(0px 0px 10px #ffffff8c) !important;
    opacity: 0.7;
}
#product-detail .process-section img.meteor-shoot {
    max-width: 300px;
    position: absolute;
    top: 9%;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
    opacity: 0.5;
    left: -9%;
}
.fashion .dis-fl{
    display:flex;
    justify-content: left;
    align-items: flex-start;
}
.fashion .dis-fl span {
    margin-bottom: 15px;
    min-width: 80px;
}
.profile-social span {
    font-weight: 900;
    font-size: 20px;
    min-width: 20px;
    display: inline-block;
}
p.visual-ai {
    padding-bottom: 10px;
}
.process-section .planet-ring {
    max-width: 400px;
    position: absolute;
    right: 0px;
    top: 0;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
    opacity: 0.3;
}
.space-margin{
    padding:30px 0;
}
.industries.bg-twinkle {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twink.png);
    background-size: cover;
    position: relative;
}
.ind.bg-sky {
    background-image: url(../utils/assets/bg-upload.png);
    background-size: contain;
    position: relative;
}
.industries .planet_green {
    max-width: 200px;
    opacity:0.9;
    position: absolute;
    right: 0px;
    top: 0;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.industries .planetary {
    max-width: 400px;
    position: absolute;
    left: -15%;
    bottom: 15%;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.industries .shoot {
    max-width: 200px;
    position: absolute;
    top: -20%;
    left: 40%;
    opacity: 0.7;
    right: 0;
}
.pricing.bg-twinkle {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twinkle.png);
    background-size: cover;
    position: relative;
    background-position: center center;
}
.pricing .rocket {
    max-width: 150px;
    position: absolute;
    right: 20px;
    top:0;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.pricing .cloud-small {
    max-width: 250px;
    position: absolute;
    left: 20px;
    top:0;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.pricing .planet-yellow {
    max-width: 300px;
    position: absolute;
    left: -15%;
    bottom:0;
    z-index:-1;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
footer .planet-pnk {
    max-width: 400px;
    position: absolute;
    right: 0;
    bottom:0;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
footer .cloud-m{
    max-width: 150px;
    position: absolute;
    left: 20%;
    top: 0;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
footer .cloud-btm{
    position: absolute;
    left: -10%;
    max-width: 300px;
    bottom: 0;
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
}
.cases-section .bg-sky{
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-case.png);
    background-size: cover;
    position:relative;
}
.cases-section .psych{
    max-width:125px;
}
.cases-section .dash{
    max-width:165px;
}
.fashion .planet-pink {
    top: 9% !important;
}
.fashion .planet-yp{
        position: absolute;
        right: 0;
        opacity: 0.3;
        top: 70%;
}
.fashion .crowd .bg-sky{
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-upload.png);
    background-size: cover;
    background-position: 100% 100%;
}
.train-model h3{
    padding: 40px 0;
    font-size: 25px;
}
.train-model .perform{
    padding: 30px 0;
}
.train-model img{
    filter: drop-shadow(0px 0px 0px #ffffff8c) !important;
    margin-right:20px;
    max-width: 90px;
}
.train-model p{
    text-align:left;
    margin-left:20px;
}
@media (max-width:1200px){
    #product-detail .hero-section h1::before, #product-detail .hero-section h1::after {
        width: 2rem;
        margin: 0 1rem;
    }
    #product-detail .cases-section {
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media (max-width:1025px){
   .object-detect .process-section .bg-purple {
        background-size: cover;
    }
    .optical .process-section .bg-purple {
         background-size: cover;
     }
}
@media (max-width:991px){	
.cases-section .mrgn{
    padding:80px 15px;
}
.price-content {
    margin: 0;
}
.price-content.middle-price {
    margin: 0;
}
#product-detail.fashion .hero-section h1::before, #product-detail.fashion .hero-section h1::after {
    display: inline-block;
    content: "";
    border-top: 0.3rem solid #da50eb;
    width: 1.5rem;
    margin: 0 0.7rem;
    transform: translateY(-0.5rem);
}
}
@media (max-width:768px){
    
.industries h3 {
    width: 100%;
}
    #product-detail .hero-section ul {
        padding: 0 0px 0 15px;
    }
    
.ind.bg-sky {
    background-size: cover;
}
.price-content.middle-price {
    margin: 0;
    min-height: 350px;
}
.price-content.middle-price span {
    font-size: 30px;
}
.pricing .mrgn {
    padding: 80px 15px;
}
.pricing .middle-price a.run-btn {
    position: absolute;
    margin: 0;
    padding: 5px 30px;
    font-size: 20px;
    margin: 20px;
}
h1::before, h1::after {
    display: none !important;
}
.process-section .bg-purple {
    background-size: cover;
}
#p
.quote h3 {
    font-size: 25px;
    font-weight: 600;
    line-height: 40px;
}
.space-margin img{
    margin-bottom:20px;
}
.price-content {
    background-position: 100% 22%;
}
.illustration-mob{
		display:none;
	}
	#product-detail .hero-section {
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-twink.png);
    background-size: cover;
    background-position: 100% 45%;
}
#product-detail .hero-section.gender {
background-image: url(../utils/assets/bg-stars.svg);
}
#product-detail .hero-section.object-d {
background-image: url(../utils/assets/bg-stars.svg);
}
#product-detail .hero-section.optical {
background-image: url(../utils/assets/bg-stars.svg);
}
#product-detail .bg-white {
    background-color: #fff !important;
    border-radius: 20px;
    color: #86387B;
    min-height: 400px;
    padding: 30px;
    text-align: center;
}
#product-detail .cases-section p {
    margin:0;
}
#product-detail .bg-white img {
    padding-bottom: 30px;
}
#product-detail .cases-section {
    text-align: center;
}
#product-detail {
	text-align:center;
}
#product-detail .cases-section h2{
	padding:40px 0 10px;
}
.case-title h2 {
    padding: 40px 0 10px;
}
#product-detail .cards {
    padding-top: 50px;
}
.cases-section .mrgn {
    padding: 50px 15px;
}
#product-detail .process-section h4 {
    padding-bottom: 10px !important;
    margin-bottom: 0;
}
.quote.spacing{
    padding-bottom: 0;
    padding-top: 50px;
}
#product-detail .product p{
	margin:0;
}
.spacing-p {
    padding: 0px 0 0px !important;
}
.process-section.spacing.bg-twinkle{
	padding-bottom:0;
}
.pricing h2 {
    padding-top:10px;
    padding-bottom: 20px;
}
.quote h3 {
    font-size: 25px;
    font-weight: 600;
    line-height: 35px;
}.industries h3 {
    line-height: 40px;
}
.train-model h3 {
    padding: 40px 0 20px;
}
.dis-fl.t-left {
    text-align: left;
}
footer.bg-twinkle {
    padding: 50px 0 30px;
}
}
@media (max-width:576px){
    
    .object-detail .bg-sky {
        background-repeat: no-repeat;
        background-size: cover;
    }
}