.lpr .bg-sky{
    background-repeat: no-repeat;
    background-image: url(../utils/assets/bg-upload.png);
    background-size: contain;
    position: relative;
    background-position: 100% 100%;
    background-repeat: repeat;
}
.lpr .industries.bg-sky{
    background-repeat: repeat;
    background-size: contain;
}
#product-detail.lpr .cases-section h2{
    padding-top:100px;
}
#product-detail.lpr .cld-med {
    position: absolute;
    left: 10%;
    top:8%;
}
.lpr .process-section .planet_blue {
    opacity: 0.4;
}
.lpr .process-section .planet_pin {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.7;
    width: 400px;
}
.lpr .industries .ast-round {
    position: absolute;
    left: 0;
    top: -31%;
    opacity: 0.7;
    width: 200px;
}
@media (max-width:1200px){
    .lpr .bg-sky{
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media (max-width:768px){
	#product-detail.lpr .cases-section h2{
    padding-top:50px;
} 
.lpr-padding{
    padding-right:15px !important;
    padding-left:15px !important;
}
.lpr .bg-sky {
    background-size: cover;
}
}
@media (max-width:476px){	
.lpr .bg-sky {
    background-size: cover;
} 
}